/* eslint-disable camelcase */
export const GL = {
  // WEBGL_compressed_texture_s3tc

  COMPRESSED_RGB_S3TC_DXT1_EXT: 0x83f0,
  COMPRESSED_RGBA_S3TC_DXT1_EXT: 0x83f1,
  COMPRESSED_RGBA_S3TC_DXT3_EXT: 0x83f2,
  COMPRESSED_RGBA_S3TC_DXT5_EXT: 0x83f3,

  // WEBGL_compressed_texture_es3

  COMPRESSED_R11_EAC: 0x9270,
  COMPRESSED_SIGNED_R11_EAC: 0x9271,
  COMPRESSED_RG11_EAC: 0x9272,
  COMPRESSED_SIGNED_RG11_EAC: 0x9273,
  COMPRESSED_RGB8_ETC2: 0x9274,
  COMPRESSED_RGBA8_ETC2_EAC: 0x9275,
  COMPRESSED_SRGB8_ETC2: 0x9276,
  COMPRESSED_SRGB8_ALPHA8_ETC2_EAC: 0x9277,
  COMPRESSED_RGB8_PUNCHTHROUGH_ALPHA1_ETC2: 0x9278,
  COMPRESSED_SRGB8_PUNCHTHROUGH_ALPHA1_ETC2: 0x9279,

  // WEBGL_compressed_texture_pvrtc

  COMPRESSED_RGB_PVRTC_4BPPV1_IMG: 0x8c00,
  COMPRESSED_RGBA_PVRTC_4BPPV1_IMG: 0x8c02,
  COMPRESSED_RGB_PVRTC_2BPPV1_IMG: 0x8c01,
  COMPRESSED_RGBA_PVRTC_2BPPV1_IMG: 0x8c03,

  // WEBGL_compressed_texture_etc1

  COMPRESSED_RGB_ETC1_WEBGL: 0x8d64,

  // WEBGL_compressed_texture_atc

  COMPRESSED_RGB_ATC_WEBGL: 0x8c92,
  COMPRESSED_RGBA_ATC_EXPLICIT_ALPHA_WEBGL: 0x8c93,
  COMPRESSED_RGBA_ATC_INTERPOLATED_ALPHA_WEBGL: 0x87ee,

  // WEBGL_compressed_texture_astc

  COMPRESSED_RGBA_ASTC_4X4_KHR: 0x93b0,
  COMPRESSED_RGBA_ASTC_5X4_KHR: 0x93b1,
  COMPRESSED_RGBA_ASTC_5X5_KHR: 0x93b2,
  COMPRESSED_RGBA_ASTC_6X5_KHR: 0x93b3,
  COMPRESSED_RGBA_ASTC_6X6_KHR: 0x93b4,
  COMPRESSED_RGBA_ASTC_8X5_KHR: 0x93b5,
  COMPRESSED_RGBA_ASTC_8X6_KHR: 0x93b6,
  COMPRESSED_RGBA_ASTC_8X8_KHR: 0x93b7,
  COMPRESSED_RGBA_ASTC_10X5_KHR: 0x93b8,
  COMPRESSED_RGBA_ASTC_10X6_KHR: 0x93b9,
  COMPRESSED_RGBA_ASTC_10X8_KHR: 0x93ba,
  COMPRESSED_RGBA_ASTC_10X10_KHR: 0x93bb,
  COMPRESSED_RGBA_ASTC_12X10_KHR: 0x93bc,
  COMPRESSED_RGBA_ASTC_12X12_KHR: 0x93bd,
  COMPRESSED_SRGB8_ALPHA8_ASTC_4X4_KHR: 0x93d0,
  COMPRESSED_SRGB8_ALPHA8_ASTC_5X4_KHR: 0x93d1,
  COMPRESSED_SRGB8_ALPHA8_ASTC_5X5_KHR: 0x93d2,
  COMPRESSED_SRGB8_ALPHA8_ASTC_6X5_KHR: 0x93d3,
  COMPRESSED_SRGB8_ALPHA8_ASTC_6X6_KHR: 0x93d4,
  COMPRESSED_SRGB8_ALPHA8_ASTC_8X5_KHR: 0x93d5,
  COMPRESSED_SRGB8_ALPHA8_ASTC_8X6_KHR: 0x93d6,
  COMPRESSED_SRGB8_ALPHA8_ASTC_8X8_KHR: 0x93d7,
  COMPRESSED_SRGB8_ALPHA8_ASTC_10X5_KHR: 0x93d8,
  COMPRESSED_SRGB8_ALPHA8_ASTC_10X6_KHR: 0x93d9,
  COMPRESSED_SRGB8_ALPHA8_ASTC_10X8_KHR: 0x93da,
  COMPRESSED_SRGB8_ALPHA8_ASTC_10X10_KHR: 0x93db,
  COMPRESSED_SRGB8_ALPHA8_ASTC_12X10_KHR: 0x93dc,
  COMPRESSED_SRGB8_ALPHA8_ASTC_12X12_KHR: 0x93dd,

  // EXT_texture_compression_rgtc

  COMPRESSED_RED_RGTC1_EXT: 0x8dbb,
  COMPRESSED_SIGNED_RED_RGTC1_EXT: 0x8dbc,
  COMPRESSED_RED_GREEN_RGTC2_EXT: 0x8dbd,
  COMPRESSED_SIGNED_RED_GREEN_RGTC2_EXT: 0x8dbe,

  // WEBGL_compressed_texture_s3tc_srgb

  COMPRESSED_SRGB_S3TC_DXT1_EXT: 0x8c4c,
  COMPRESSED_SRGB_ALPHA_S3TC_DXT1_EXT: 0x8c4d,
  COMPRESSED_SRGB_ALPHA_S3TC_DXT3_EXT: 0x8c4e,
  COMPRESSED_SRGB_ALPHA_S3TC_DXT5_EXT: 0x8c4f
};
